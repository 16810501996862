



import ResetPasswordConfirmContainer from '@/container/auth/ResetPasswordConfirmContainer.vue';
import Vue from 'vue';
export default Vue.extend({
  name: 'ResetPasswordConfirmScene',

  components: {
    ResetPasswordConfirmContainer,
  },
});
