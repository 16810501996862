

































































































import Vue from 'vue';
import { resetPasswordModule } from '@/store/viewModule/auth/resetPasswordModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { resetPasswordConfirmModule } from '@/store/viewModule/auth/resetPasswordConfirmModule';

interface DataType {
  teamId: string;
  email: string;
  password: string;
  passwordConfirm: string;
  isValid: boolean;
  confirmationCode: string;
}

export default Vue.extend({
  name: 'ResetPasswordConfirmContainer',

  data: (): DataType => {
    return {
      teamId: '',
      email: '',
      password: '',
      passwordConfirm: '',
      isValid: false,
      confirmationCode: '',
    };
  },

  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('validator.required'),
        email: (email) => {
          const emailRegExp = /^[A-Za-z0-9]{1}[A-Za-z0-9_._-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
          return emailRegExp.test(email) || this.$t('validator.email');
        },
        passwordLength: (password) => {
          return password.trim().length >= 8 || this.$t('validator.passwordLength');
        },
        password: (password) => {
          const passwordRegExp = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]{1,100}$/;
          return passwordRegExp.test(password) || this.$t('validator.passwordChars');
        },
      };
    },
    isPaswordConfirmed() {
      if (this.password.trim().length === 0 || this.passwordConfirm.trim().length === 0) {
        return false;
      }
      return this.password === this.passwordConfirm;
    },
    showLoader() {
      return commonModule.showLoader;
    },
  },

  methods: {
    resetPassword() {
      commonModule.setLoader(true);
      resetPasswordConfirmModule.resetPasswordConfirm({
        teamId: this.teamId,
        email: this.email,
        confirmationCode: this.confirmationCode,
        password: this.password,
      }).then((data) => {
        commonModule.setLoader(false);
        const response: any = data;
        if (response && response.email === this.email) {
          alert(this.$t('passwordResetCompleted'));
          this.$router.push({ name: 'signin' });
        } else if (data.error) {
          switch (data.error) {
            case 'E003':
              alert(this.$t('errorE003'));
              break;
            case 'E007':
              alert(this.$t('errorE007'));
              break;
            case 'E005':
              alert(this.$t('errorE005'));
              break;
            case 'E032':
              alert(this.$t('errorE032'));
              break;
            case 'E039':
              alert(this.$t('errorE039'));
              break;
            case 'E040':
              alert(this.$t('errorE040'));
              break;
            default:
              alert(this.$t('errorAuthAlert'));
              break;
          }
        }
      });
    },
  },

  mounted() {
    const teamId = this.$route.query.team_id as ( string | null );
    const email = this.$route.query.email as ( string | null );
    const confirmationCode = this.$route.query.confirmation_code as ( string | null );
    if (/*clientId && */teamId && email && confirmationCode) {
      // this.clientId = clientId;
      this.teamId = teamId;
      this.email = email;
      this.confirmationCode = confirmationCode;
    }
  },
});
