import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { authorizeResetPassword } from '@/api/client';

export interface ResetPasswordConfirmState {}

@Module({ dynamic: true, store, name: 'reset-password-confirm', namespaced: true })
class ResetPasswordConfirmModule extends VuexModule implements ResetPasswordConfirmState {

  @Action
  public async resetPasswordConfirm({ teamId, email, confirmationCode, password }: {
    teamId: string,
    email: string,
    confirmationCode: string,
    password: string,
  }) {
    return authorizeResetPassword({ teamId, email, newPassword: password, confirmationCode});
  }
}

export const resetPasswordConfirmModule = getModule(ResetPasswordConfirmModule);
